import React from "react";

const WorkItems = ({ item }) => {
  const handleImageClick = () => {
    window.open(item.websiteUrl, "_blank");
  };

  const cardClassName = `work__card ${item.title
    .toLowerCase()
    .replace(/\s+/g, "-")}-card`;

  return (
    <div className={cardClassName} key={item.id}>
      <h3 className="work__title">{item.title}</h3>
      <div className="work__img-container">
        <a href={item.websiteUrl} target="_blank" rel="noopener noreferrer">
          <img
            src={item.image}
            alt="website"
            class="work__img"
            onClick={handleImageClick}
          />
        </a>
      </div>
      <p
        className="work__description"
        style={{ maxWidth: "490px", whiteSpace: "pre-wrap" }}
      >
        {item.description}
      </p>
      <div className="work__buttons">
        <a
          href={item.websiteUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="work__button"
        >
          Demo
          <i className="bx bx-right-arrow-alt work__button-icon"></i>
        </a>
        {item.githubUrl && (
          <a
            href={item.githubUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="work__button"
          >
            GitHub
            <i className="bx bxl-github work__button-icon"></i>
          </a>
        )}
        {item.groupProject && (
          <button className="work__button__group">
            Group Project
            <i className="bx bx-group work__button-icon"></i>
          </button>
        )}
        {item.soloProject && (
          <button className="work__button__group">
            Solo Project
            <i className="bx bx-user work__button-icon"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default WorkItems;
