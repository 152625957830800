import React from "react";
import "./about.css";
import AboutImg from "../../assets/about.png";

const About = () => {
  return (
    <section className="about section" id="about">
      <h2 className="section__title">About Me &#129312;</h2>
      <span className="section__subtitle">My introduction</span>
      <div className="about__container container grid">
        <img src={AboutImg} alt="" className="about__img" />

        <div className="about__data">
          <div className="about__description">
            <p style={{ textIndent: "25px" }}>
              Coding Junior in the week, mountain hiker on the weekend. I'm here
              to craft clean, efficient code and explore the endless
              possibilities that software engineering has to offer. My
              commitment to continuous improvement led me to successfully
              complete an intensive Web Development Bootcamp in London, where I
              immersed myself in the dynamic world of web development.
            </p>
            <br></br>
            <p style={{ textIndent: "25px" }}>
              With experience spanning various programming languages, I am
              committed to deliverying the best code I can. My dedication to
              growth extends to my participation in conferences, meetups, and
              workshops, such as codebar, which has allowed me to continually
              expand my knowledge and network.
            </p>
            <br></br>
            <p style={{ textIndent: "25px" }}>
              Currently, I am actively engaged in client projects while
              freelancing and pursuing personal projects to build my skills. You
              can explore some of my showcased work below. If you'd like to
              discuss anything further, please don't hesitate to reach out—I'm
              always eager for a conversation.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
