import Work1 from "../../assets/work1.png";
import vegilicious_homepage from "../../assets/vegilicious_homepage.png";
import dev_community from "../../assets/dev_community.png";
import e_commerce_shop from "../../assets/e-commerce-shop.png";

export const projectsData = [
  {
    id: 1,
    websiteUrl: "https://e-commerce-shop1-ff023a051520.herokuapp.com/",
    title: "E-commerce-shop",
    category: "web",
    githubUrl: "https://github.com/robimaliqi/e-commerce-shop",
    image: e_commerce_shop,
    description:
      "A e-commerce shop where the User is able to sign up, log in and buy items using stripe integration. Ruby on Rails, Turbo-Hotwire and bootstrap.",
    soloProject: true,
  },
  {
    id: 2,
    websiteUrl: "https://rails-0u0b.onrender.com",
    title: "Dev-Community",
    category: "web",
    githubUrl: "https://github.com/robimaliqi/dev-community",
    image: dev_community,
    description:
      "An app similar to Linkedin, exclusively tailored for developers. Ruby on Rails, Turbo-Hotwire, Stimulus JS, Bootstrap.",
    soloProject: true,
  },
  {
    id: 3,
    websiteUrl: "https://beachlife.vercel.app/",
    title: "Beach Finder",
    category: "web",
    githubUrl: "https://github.com/robimaliqi/beach-life",
    image: Work1,
    description:
      "An one stop shop for all coastal destinations in the United Kingdom, this project was developed utilizing the MERN (MongoDB, Express.js, React, Node.js) Stack.",
    groupProject: true,
  },
  {
    id: 3,
    websiteUrl: "https://vegilicious-frontend.onrender.com",
    title: "Vegilicous",
    category: "web",
    githubUrl: "https://github.com/robimaliqi/v46-tier3-team-33",
    image: vegilicious_homepage,
    description:
      "A farmer app for posting produce with photos and descriptions, allowing users to express buying interest. Users can purchase, collect produce, and share reviews. React, Typescript, Express, Node.",
    groupProject: true,
  },
];

// projects
export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "web",
  },
  {
    name: "app",
  },
];
