import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./contact.css";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_x1hewh5",
      "template_2ki8hlo",
      form.current,
      "vxDcl4UI2jbOQBn6f"
    );
    e.target.reset();
  };

  return (
    <section className="contact section" id="contact">
      <h2 className="section__title">Get in touch &#128233;</h2>
      <span className="section__subtitle">Contact Me</span>

      <div className="contact__container container grid">
        <div className="contact__content">
          <h3 className="contact__title">Talk to me</h3>

          <div className="contact__info">
            <div className="contact__card">
              <a
                href="mailto:robi.maliqi@gmail.com"
                className="contact__card-link"
              >
                <i className="bx bx-mail-send contact__card-icon"></i>
              </a>

              <h3 className="contact__card-title">Email</h3>
              <span className="contact__card-data">robi.maliqi@gmail.com</span>

              <a
                href="mailto:robi.maliqi@gmail.com"
                className="contact__button"
              >
                Contact me{" "}
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
